@import '../../../cl_base/scss/theme-bootstrap';

#contact-us-page {
  #contact-us-container {
    @include breakpoint ($cr19-large) {
      padding: 50px 15px;
    }
  }
  .personal-info-form-container {
    @include breakpoint ($cr19-large) {
      width: 100%;
    }
  }
  .drupal-embed {
    @include breakpoint ($cr19-large) {
      width: 500px;
    }
  }
  .desc {
    width: 80%;
  }
  .form-builder-container {
    .form-item-form-COMMENTS {
      @include breakpoint ($cr19-large) {
        float: left;
        left: 0;
        margin-top: 0;
        position: relative;
        width: 447px;
      }
    }
    .form-type-radios.form-item-form-NEWLETTERS {
      margin-top: 195px;
      @include breakpoint ($cr19-large) {
        float: left;
        left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
        width: 447px;
      }
    }
    .form-item-form-ACCEPTED-PRIVACY-POLICY {
      float: right;
      left: 50%;
      margin-top: 230px;
      position: absolute;
      width: 490px;
      dd {
        margin: 0 auto;
      }
      @include breakpoint ($cr19-large) {
        float: left;
        left: 0;
        margin-top: 0;
        margin-bottom: 15px;
        position: relative;
        width: 447px;
      }
    }
    #edit-form-comments {
      height: 165px;
    }
    #edit-submit {
      &.form-submit {
        @include breakpoint ($cr19-large) {
          float: left;
          left: 0;
          margin-top: 0;
          position: relative;
        }
      }
    }
    @include breakpoint ($cr19-large) {
      width: 100%;
    }
  }
}
